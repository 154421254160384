import React from 'react';
import { Box, Heading, Text } from '@chakra-ui/core';
import { Helmet } from 'react-helmet';

export default function standards() {
  return (
    <Box mt="100px" mb="100px">
      <Helmet>
        <title> Termini e Condizioni</title>
      </Helmet>
      <Heading
        mr="4"
        pl={['5%', '5%', '20%', '20%']}
        pr={['5%', '5%', '20%', '20%']}
        size="xl"
      >
        Termini di Utilizzo dei Siti di Al-Mutawassit Publications
      </Heading>
      <Box
        textAlign="Left"
        pl={['5%', '5%', '20%', '20%']}
        pr={['5%', '5%', '20%', '20%']}
      >
        <Text fontSize="2xl" m="4">
          Si prega di leggere attentamente i termini di utilizzo prima di registrarsi, navigare o condividere file sul sito di Al-Mutawassit Publications e sui siti ad esso collegati.
        </Text>

        <Text fontSize="2xl" m="4">
          L'utilizzo o la partecipazione a questi siti implica la lettura e l'accettazione di questi termini. Se non accetti di rispettarli, ti preghiamo di uscire da tutti questi siti.
        </Text>

        <Text fontSize="2xl" m="4">
          Al-Mutawassit Publications si riserva il diritto di aggiornare i termini di utilizzo ogni volta che è necessario, pertanto ti preghiamo di continuare a controllare gli aggiornamenti dei termini di utilizzo.
        </Text>
        <br />
        <Heading size="lg" m="4">
          Disclaimer
        </Heading>
        <Text fontSize="2xl" m="4">
          Al-Mutawassit Publications fa ogni sforzo per garantire che le informazioni e i servizi sui propri siti web siano accurati al momento della pubblicazione, ma non fornisce alcuna garanzia espressa o implicita sull'accuratezza delle informazioni.
        </Text>

        <Text fontSize="2xl" m="4">
          Al-Mutawassit Publications non è responsabile per eventuali conseguenze, perdite o danni che possono verificarsi all'utente o a terzi a causa dell'utilizzo dei siti e dei servizi di Al-Mutawassit Publications. Questo vale per qualsiasi contenuto pubblicato o per eventuali omissioni.
        </Text>

        <Text fontSize="2xl" m="4">
          I link a siti esterni non implicano alcuna relazione, commerciale o altro, tra i siti di Al-Mutawassit Publications e i siti web dei terzi citati.
        </Text>

        <Text fontSize="2xl" m="4">
          Questi link sono forniti come sono per facilitare la navigazione dei visitatori del sito, e Al-Mutawassit Publications non è responsabile di condurre verifiche periodiche, approvare, esaminare o convalidare i contenuti o l'uso di prodotti o servizi offerti da tali siti.
        </Text>

        <Text fontSize="2xl" m="4">
          Al-Mutawassit Publications fa ogni sforzo per garantire che il contenuto del sito sia privo di virus, ma non è responsabile per eventuali danni derivanti dall'uso del contenuto pubblicato, e la responsabilità di utilizzare le informazioni disponibili su questi siti ricade sull'utente.
        </Text>

        <Text fontSize="2xl" m="4">
          Infine, accetti di non accedere o tentare di accedere a qualsiasi account utente, né di alterare o tentare di alterare la tua identità durante l'uso dei siti.
        </Text>
        <br />
        <Heading size="lg" m="4">
          Utilizzo del Sito di Al-Mutawassit Publications e dei Siti Collegati e Contenuto Pubblicato
        </Heading>
        <Text fontSize="2xl" m="4">
          Al-Mutawassit Publications si riserva tutti i diritti. Non è permesso copiare, utilizzare o ripubblicare qualsiasi parte del materiale dei suoi siti web, sia in forma cartacea che elettronica, né conservarlo in un sistema di recupero delle informazioni o trasmetterlo in qualsiasi forma senza l'autorizzazione scritta della casa editrice. Il contenuto può essere utilizzato per scopi educativi o per pubblicare libri per ipovedenti o non vedenti, a condizione che la casa editrice venga informata. Sono escluse anche le brevi citazioni utilizzate nelle recensioni giornalistiche.
        </Text>

        <Text fontSize="2xl" m="4">
          I siti web di Al-Mutawassit Publications non devono essere utilizzati né contattati per:
        </Text>

        <Text fontSize="2xl" m="4">
          Commettere atti illegali.
        </Text>

        <Text fontSize="2xl" m="4">
          Falsificare l'identità.
        </Text>

        <Text fontSize="2xl" m="4">
          Pubblicare contenuti offensivi.
        </Text>
        <br />

        <Heading size="lg" m="4">
          Indennizzo
        </Heading>
        <Text fontSize="2xl" m="4">
          Accetti di indennizzare Al-Mutawassit Publications e di proteggerla da qualsiasi reclamo o perdita o danno, comprese le spese legali, che possano derivare dalla violazione di questi termini o dall'uso del sito o dall'aggiunta di qualsiasi contenuto, e accetti di collaborare pienamente nella difesa di Al-Mutawassit Publications contro tali reclami.
        </Text>
        <br />
        <Heading size="lg" m="4">
          Il Tuo Account
        </Heading>
        <Text fontSize="2xl" m="4">
          Se utilizzi questo sito sei responsabile di mantenere la riservatezza delle informazioni del tuo account e della tua password - se presente - e di limitare l'accesso al tuo computer, e accetti di essere responsabile di tutte le attività che si verificano sotto il tuo account o la tua password.
        </Text>

        <Text fontSize="2xl" m="4">
          Al-Mutawassit Publications si riserva il diritto di rifiutare il servizio, terminare gli account, rimuovere o modificare il contenuto.
        </Text>
        <br />
        <Heading size="lg" m="4">
          Reclami sui Diritti d'Autore
        </Heading>
        <Text fontSize="2xl" m="4">
          Al-Mutawassit Publications rispetta i diritti di proprietà intellettuale degli altri, e se ritieni che i tuoi diritti d'autore siano stati violati su uno dei siti della casa editrice, ti preghiamo di contattare:
        </Text>
        <br />
        <Heading size="lg" m="4">
          Gestione di Al-Mutawassit Publications
        </Heading>
        <Text fontSize="2xl" m="4">
          Numero di telefono: 00393477104442
        </Text>
        <Text fontSize="2xl" m="4">
          Email: info@almutawassit.org
        </Text>
      </Box>
    </Box>
  );
}
