import React from 'react';
import { Helmet } from 'react-helmet';

import { Box, Heading, Text } from '@chakra-ui/core';

export default function publishlist() {
  return (
    <Box mt="100px" mb="100px">
      <Helmet>
        <title>Festival della Cultura Araba a Milano</title>
      </Helmet>
      <Heading
        fontFamily="diodrum-bold !important"
        pl={['5%', '5%', '20%', '20%']}
        pr={['5%', '5%', '20%', '20%']}
        size="xl"
      >
        Festival della Cultura Araba a Milano
      </Heading>
      <Box
        textAlign="left"
        p="30px"
        pl={['5%', '5%', '20%', '20%']}
        pr={['5%', '5%', '20%', '20%']}
      >
        <Text fontSize="2xl" m="4">
          Mediterranea Edizioni è partner organizzativo del "Festival
          Internazionale della Lingua Araba e delle sue Culture" in collaborazione
          con l'Università Cattolica di Milano, una delle università più
          prestigiose in Italia e nel mondo. Durante il festival, Mediterranea
          organizza una mostra del libro arabo e del libro italiano sul mondo
          arabo, insieme a diverse attività letterarie e artistiche che vedono la
          partecipazione di scrittori e artisti arabi e italiani nella città di
          Milano.
        </Text>
        <Text fontSize="2xl" m="4">
          Durante i giorni del festival, per quattro edizioni consecutive, si
          svolgono eventi tra cui la mostra del libro arabo e del libro italiano
          sul mondo arabo, con la partecipazione di diverse case editrici arabe.
          La direzione di Mediterranea Edizioni e del comitato organizzativo
          garantisce la presenza di case editrici come Dar al-Jadid, al-Saqi,
          al-Tanweer, al-Mada, l'Istituto Arabo per gli Studi e le Pubblicazioni,
          al-Farabi, Mahmud Dawan, al-Ain e Mediterranea, con l'obiettivo di
          offrire uno spazio di interazione e dialogo per i libri arabi e
          mettere in evidenza le opere creative degli scrittori arabi in vari
          campi intellettuali e letterari.
        </Text>
        <Text fontSize="2xl" m="4">
          La fiera vede anche la partecipazione di importanti biblioteche
          europee, con stand dedicati ai libri che trattano e traducono temi
          riguardanti il mondo arabo in Italia. Il programma del festival include
          serate di poesia, concerti musicali professionisti e proiezioni
          cinematografiche, che riflettono le molteplici facce del panorama
          poetico, cinematografico e artistico arabo, nonostante le diverse
          geografie e nazionalità coinvolte.
        </Text>
        <Text fontSize="2xl" m="4">
          Il festival è uno spazio culturale aperto alla letteratura, alla
          musica, al cinema e ai seminari intellettuali del convegno che, nella
          sua edizione del 2019, ha cercato di rispondere a diverse domande
          relative al ruolo delle donne nel campo linguistico e creativo. Tra
          queste domande: quando le donne arabe hanno fatto il loro ingresso
          nello spazio culturale pubblico della società moderna nei campi
          dell'istruzione, del giornalismo, della scrittura letteraria, del
          teatro, del cinema, del canto e della musica? Qual è stato il contesto
          culturale che ha governato questa presenza? E quali sono stati i suoi
          effetti sulla lingua araba contemporanea? Ha portato a un cambiamento
          nell'immagine e nel simbolismo delle donne nell'immaginario collettivo
          e nelle opere letterarie?
        </Text>
        <Text fontSize="2xl" m="4">
          Il festival ha anche esplorato l'impatto di tutto ciò sui diritti delle
          donne e se ha portato a cambiamenti nei testi legislativi e
          costituzionali. Quali sono stati gli ostacoli linguistici e culturali
          che hanno impedito una piena integrazione delle donne nella società?
          Possiamo parlare di una letteratura araba femminile? Cosa la
          distingue, linguisticamente e esteticamente, da altre scritture
          letterarie?
        </Text>
        <Text fontSize="2xl" m="4">
          Queste e altre domande sono state affrontate e discusse da professori,
          esperti e appassionati di questioni femminili nei campi della lingua e
          della creatività, provenienti da diversi paesi arabi e con la
          partecipazione di docenti e ricercatori italiani.
        </Text>
        <Text fontWeight="bold" fontSize="2xl" m="4">
          Il Festival della Cultura Araba a Milano è un evento annuale
          organizzato dall'Università Cattolica di Milano ogni primavera, con
          Mediterranea come partner principale nell'organizzazione, con l'obiettivo
          di creare uno spazio letterario e artistico aperto tra creatori arabi e
          stranieri, tra studenti universitari e esperienze creative arabe in
          vari campi.
        </Text>
      </Box>
    </Box>
  );
}
