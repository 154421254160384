import React from 'react';
import { Box, Heading, Text } from '@chakra-ui/core';
import { Helmet } from 'react-helmet';

export default function CookiePolicy() {
  return (
    <Box mt="100px" mb="100px">
      <Helmet>
        <title>Politica sui cookie</title>
      </Helmet>
      <Heading
        mr="4"
        pl={['5%', '5%', '20%', '20%']}
        pr={['5%', '5%', '20%', '20%']}
        size="xl"
      >
        Politica sui cookie
      </Heading>
      <Box
        textAlign="left"
        pl={['5%', '5%', '20%', '20%']}
        pr={['5%', '5%', '20%', '20%']}
      >
        <Text fontSize="2xl" m="4">
          Utilizziamo cookie su tutti i siti web affiliati a Pubblicazioni Al-Mutawassit per migliorare le prestazioni del servizio del sito, consentire ai nostri visitatori di navigare tra le pagine con totale comodità e conservare nelle nostre memorie le impostazioni preferite dai nostri visitatori, permettendoci di migliorare la loro esperienza complessiva sul nostro sito. Utilizziamo anche i cookie per analizzare come gli utenti utilizzano il servizio, come interagiscono con il contenuto che offriamo e come lo ricevono. Consentiamo a terze parti di fiducia di utilizzare i cookie e tecnologie simili per questi scopi.
        </Text>

        <Text fontSize="2xl" m="4">
          Utilizzando questo sito, accettate questa politica e l'invio di cookie a voi.
        </Text>
        <br />
        <Heading size="lg" m="4">
          Cosa sono i cookie?
        </Heading>
        <Text fontSize="2xl" m="4">
          I cookie sono piccoli file di testo che contengono quantità di informazioni che vengono inviate al browser che usate e memorizzate nel vostro computer, telefono cellulare o altro dispositivo quando visitate un sito web. I cookie inviano informazioni al sito ogni volta che lo visitate di nuovo. I cookie possono essere permanenti (in tal caso sono noti come cookie persistenti); rimangono nel vostro computer fino a quando non li eliminate, oppure temporanei (sono noti come cookie di sessione); durano solo fino a quando chiudete il browser. I cookie possono essere di prima parte, ovvero quelli impostati dal sito web che state visitando attualmente, o di terza parte, impostati da qualcuno diverso dal proprietario del sito web che state visitando.
        </Text>
        <br />
        <Heading size="lg" m="4">
          I cookie che utilizziamo e la loro importanza:
        </Heading>
        <Text fontSize="2xl" m="4">
          I cookie che utilizziamo nel servizio possono essere classificati come segue:
        </Text>
        <br />
        <Heading size="lg" m="4">
          Cookie necessari:
        </Heading>
        <Text fontSize="2xl" m="4">
          Sono cookie necessari per il funzionamento del nostro sito web, permettendovi di navigare nel sito e di utilizzare le funzionalità che forniamo.
        </Text>
        <br />
        <Heading size="lg" m="4">
          Cookie relativi alle prestazioni:
        </Heading>
        <Text fontSize="2xl" m="4">
          Questi cookie raccolgono informazioni su come i visitatori utilizzano un sito web, ad esempio quali pagine vengono visitate più spesso e la durata della visita. Utilizziamo questi cookie per fornire statistiche su come viene utilizzato il servizio. Questi dati vengono anche utilizzati per determinare se i clienti hanno esigenze o interessi specifici in base alle pagine visitate sui nostri siti/applicazioni, aiutandoci a fornire un servizio migliore. Misurare l'efficacia delle nostre campagne pubblicitarie.
        </Text>
        <br />
        <Heading size="lg" m="4">
          Cookie relativi alle funzionalità:
        </Heading>
        <Text fontSize="2xl" m="4">
          Questi cookie permettono al sito web di ricordare le impostazioni che avete definito e forniscono funzionalità migliorate e più personalizzate. Possono anche essere utilizzati per fornire servizi che avete richiesto, come guardare un video o lasciare commenti in un blog.
        </Text>
        <br />
        <Heading size="lg" m="4">
          Utilizziamo i cookie per:
        </Heading>
        <Text fontSize="2xl" m="4">
          Ricordare se un servizio vi è stato fornito o meno. Migliorare l'esperienza complessiva del sito web ricordando le impostazioni che preferite. Questi cookie possono essere permanenti o temporanei, di prima o di terza parte. In breve, ricordano le scelte che avete fatto per migliorare la vostra esperienza come utente.
        </Text>
        <br />
        <Heading size="lg" m="4">
          Cookie relativi alla pubblicità:
        </Heading>
        <Text fontSize="2xl" m="4">
          Questi cookie vengono utilizzati per determinare quante volte vedete un annuncio e aiutano a misurare l'efficacia delle campagne pubblicitarie. Questi cookie vengono utilizzati per gestire le pubblicità sul sito web. Vengono impostati da terze parti autorizzate, come gli inserzionisti e i loro agenti, e possono essere cookie permanenti o temporanei. In breve, questi cookie sono relativi ai servizi pubblicitari forniti da terze parti sul nostro sito web.
        </Text>
        <br />
        <Heading size="lg" m="4">
          Altri cookie di terze parti:
        </Heading>
        <Text fontSize="2xl" m="4">
          Su alcune pagine del nostro sito web, possiamo avere contenuti di servizi come YouTube o Dailymotion. Si noti che non controlliamo i cookie utilizzati da questi servizi e vi consigliamo di verificare il sito web di terze parti interessato per ulteriori informazioni sui loro cookie.
        </Text>
        <br />
        <Heading size="lg" m="4">
          Come gestire ed eliminare i cookie:
        </Heading>
        <Text fontSize="2xl" m="4">
          Se desiderate limitare, bloccare o eliminare i cookie, potete farlo tramite le impostazioni del vostro browser web. I passaggi necessari variano a seconda del browser che utilizzate. Cliccate sui link dei browser qui sotto per ottenere le istruzioni.
        </Text>
      </Box>
    </Box>
  );
}
