import React from 'react';
import { Helmet } from 'react-helmet';

import { Box, Heading, Text } from '@chakra-ui/core';

export default function publishlist() {
  return (
    <Box mt="100px" mb="100px">
      <Helmet>
        <title>La Letteratura è più Forte</title>
      </Helmet>
      <Heading
        fontFamily="diodrum-bold !important"
        pl={['5%', '5%', '20%', '20%']}
        pr={['5%', '5%', '20%', '20%']}
        size="xl"
      >
        La Letteratura è più Forte
      </Heading>
      <Box
        textAlign="left"
        p="30px"
        pl={['5%', '5%', '20%', '20%']}
        pr={['5%', '5%', '20%', '20%']}
      >
        <Text fontSize="2xl" m="4">
          Mediterranea Edizioni ha lanciato nel 2016 una serie letteraria
          intitolata "La Letteratura è più Forte", che include l'edizione
          palestinese di diversi suoi libri contemporaneamente alla loro uscita
          nel mondo arabo. Questo rappresenta un'opportunità per i libri della
          casa editrice di far parte di un lavoro resistente contro le
          autorità occupanti, rompendo l'isolamento imposto ai lettori
          palestinesi e ai libri desiderosi di essere presenti nelle terre
          palestinesi occupate.
        </Text>
        <Text fontSize="2xl" m="4">
          In un'intervista alla rivista culturale "Rumman", il direttore di
          Mediterranea Edizioni, Khalid Sulaiman al-Nasiri, ha dichiarato: "Il
          sogno di ogni palestinese all'estero è il ritorno, e questo ritorno
          può essere incarnato nel lancio dei nostri progetti dall'interno della
          Palestina, rappresentando una forma di ritorno."
        </Text>
        <Text fontSize="2xl" m="4">
          Ha aggiunto che "l'idea è nata dopo la partecipazione della casa
          editrice alla Fiera Internazionale del Libro di Palestina nel 2016,
          dove abbiamo affrontato una situazione molto fastidiosa. I libri di
          Mediterranea, insieme a molti altri libri arabi, sono rimasti
          bloccati al confine con lo Stato di Israele e sono entrati solo il
          ​​ultimo giorno della fiera, negli ultimi due ore. Successivamente,
          abbiamo seguito ciò che è successo con i nostri amici nella casa
          editrice digitale di distribuzione, che hanno preso nuove edizioni
          di noi dalla Fiera Internazionale del Libro di Amman. Il ritardo
          dei libri nei paesi è stato anche coincidente con l'arrivo al
          confine dello Stato di Israele con una lunga pausa religiosa,
          specialmente per loro, senza alcuna relazione con i palestinesi.
          Inoltre, ci sono state perdite finanziarie che ci hanno costretto
          a pagare come tasse di stoccaggio fino alla fine di quel guasto!"
        </Text>
        <Text fontSize="2xl" m="4">
          "Abbiamo poi avuto discussioni e scambi di idee con amici
          intellettuali palestinesi sull'idea dell'edizione palestinese, che
          ha incontrato l'apprezzamento di tutti ed è sembrata una soluzione
          eccellente. Le autorità di occupazione possono ritardare e ostacolare
          l'arrivo dei libri, possono anche vietarne l'ingresso se lo desiderano.
          Bene, allora stampiamo qui. E così abbiamo iniziato e qui è il nostro
          primo libro che esce lì."
        </Text>
        <Text fontSize="2xl" m="4" fontWeight="bold">
          "La Letteratura è più Forte" è un'iniziativa culturale volta a
          creare un ponte conoscitivo tra scrittori e libri in Palestina e
          al di fuori di essa, permettendo di superare molte delle barriere
          e degli ostacoli imposti dall'occupazione sionista per prevenire
          l'arrivo del libro arabo in Palestina, il cui obiettivo è approfondire
          l'assedio e l'isolamento culturale tra Palestina, mondo arabo e
          umanità.
        </Text>
      </Box>
    </Box>
  );
}
