import React from 'react';
import Masonry from 'react-masonry-css';
import { Helmet } from 'react-helmet';

import {
  Box,
  Heading,
  Text,
  Button,
  Flex,
  Image,
  Skeleton,
  Spinner,
  useColorMode,
} from '@chakra-ui/core';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { getAuthors } from '../../redux/actions/authorActions';

function Writers({ getAuthors }) {
  const { colorMode } = useColorMode();

  const bg = { light: 'white', dark: '#151a23' };
  const [data, setData] = React.useState(null);
  const [loaded, setLoaded] = React.useState(false);
  const imageLoaded = () => {
    setLoaded(true);
  };
  React.useEffect(() => {
    async function getData() {
      const res = await getAuthors();
      setData(res.data);
    }
    getData();
  }, []);

  const getFiltredAuthors = async letter => {
    const res = await getAuthors(letter);
    setData(res.data);
  };

  const breakpointColumnsObj = {
    default: 3,
    1300: 3,
    1100: 2,
    1000: 1,
  };

  return (
    <Box className="padding" mt={{ base: '2em', md: '6em' }} mb="100px">
      <Helmet>
      <title>Scrittori Al-Mutawassit</title>
      </Helmet>
      <Heading fontFamily="diodrum-bold !important" size="xl">
      Scrittori Al-Mutawassit
      </Heading>
      <Flex
  mt="8"
  overflowX={{ base: 'auto', sm: 'auto' }}
  wrap={['nowrap', 'nowrap', 'wrap', 'wrap']}
  direction={['row', 'row', 'row', 'row']}
>
  {/* A to M */}
  <Button
    _focus={{ bg: 'black', color: 'white' }}
    onClick={() => getFiltredAuthors('a')}
    fontWeight="normal"
    fontSize="2xl"
    m="2"
  >
    A
  </Button>
  <Button
    _focus={{ bg: 'black', color: 'white' }}
    onClick={() => getFiltredAuthors('b')}
    fontWeight="normal"
    fontSize="2xl"
    m="2"
  >
    B
  </Button>
  <Button
    _focus={{ bg: 'black', color: 'white' }}
    onClick={() => getFiltredAuthors('c')}
    fontWeight="normal"
    fontSize="2xl"
    m="2"
  >
    C
  </Button>
  <Button
    _focus={{ bg: 'black', color: 'white' }}
    onClick={() => getFiltredAuthors('d')}
    fontWeight="normal"
    fontSize="2xl"
    m="2"
  >
    D
  </Button>
  <Button
    _focus={{ bg: 'black', color: 'white' }}
    onClick={() => getFiltredAuthors('e')}
    fontWeight="normal"
    fontSize="2xl"
    m="2"
  >
    E
  </Button>
  <Button
    _focus={{ bg: 'black', color: 'white' }}
    onClick={() => getFiltredAuthors('f')}
    fontWeight="normal"
    fontSize="2xl"
    m="2"
  >
    F
  </Button>
  <Button
    _focus={{ bg: 'black', color: 'white' }}
    onClick={() => getFiltredAuthors('g')}
    fontWeight="normal"
    fontSize="2xl"
    m="2"
  >
    G
  </Button>
  <Button
    _focus={{ bg: 'black', color: 'white' }}
    onClick={() => getFiltredAuthors('h')}
    fontWeight="normal"
    fontSize="2xl"
    m="2"
  >
    H
  </Button>
  <Button
    _focus={{ bg: 'black', color: 'white' }}
    onClick={() => getFiltredAuthors('i')}
    fontWeight="normal"
    fontSize="2xl"
    m="2"
  >
    I
  </Button>
  <Button
    _focus={{ bg: 'black', color: 'white' }}
    onClick={() => getFiltredAuthors('j')}
    fontWeight="normal"
    fontSize="2xl"
    m="2"
  >
    J
  </Button>
  <Button
    _focus={{ bg: 'black', color: 'white' }}
    onClick={() => getFiltredAuthors('k')}
    fontWeight="normal"
    fontSize="2xl"
    m="2"
  >
    K
  </Button>
  <Button
    _focus={{ bg: 'black', color: 'white' }}
    onClick={() => getFiltredAuthors('l')}
    fontWeight="normal"
    fontSize="2xl"
    m="2"
  >
    L
  </Button>
  <Button
    _focus={{ bg: 'black', color: 'white' }}
    onClick={() => getFiltredAuthors('m')}
    fontWeight="normal"
    fontSize="2xl"
    m="2"
  >
    M
  </Button>

  {/* N to Z */}
  <Button
    _focus={{ bg: 'black', color: 'white' }}
    onClick={() => getFiltredAuthors('n')}
    fontWeight="normal"
    fontSize="2xl"
    m="2"
  >
    N
  </Button>
  <Button
    _focus={{ bg: 'black', color: 'white' }}
    onClick={() => getFiltredAuthors('o')}
    fontWeight="normal"
    fontSize="2xl"
    m="2"
  >
    O
  </Button>
  <Button
    _focus={{ bg: 'black', color: 'white' }}
    onClick={() => getFiltredAuthors('p')}
    fontWeight="normal"
    fontSize="2xl"
    m="2"
  >
    P
  </Button>
  <Button
    _focus={{ bg: 'black', color: 'white' }}
    onClick={() => getFiltredAuthors('q')}
    fontWeight="normal"
    fontSize="2xl"
    m="2"
  >
    Q
  </Button>
  <Button
    _focus={{ bg: 'black', color: 'white' }}
    onClick={() => getFiltredAuthors('r')}
    fontWeight="normal"
    fontSize="2xl"
    m="2"
  >
    R
  </Button>
  <Button
    _focus={{ bg: 'black', color: 'white' }}
    onClick={() => getFiltredAuthors('s')}
    fontWeight="normal"
    fontSize="2xl"
    m="2"
  >
    S
  </Button>
  <Button
    _focus={{ bg: 'black', color: 'white' }}
    onClick={() => getFiltredAuthors('t')}
    fontWeight="normal"
    fontSize="2xl"
    m="2"
  >
    T
  </Button>
  <Button
    _focus={{ bg: 'black', color: 'white' }}
    onClick={() => getFiltredAuthors('u')}
    fontWeight="normal"
    fontSize="2xl"
    m="2"
  >
    U
  </Button>
  <Button
    _focus={{ bg: 'black', color: 'white' }}
    onClick={() => getFiltredAuthors('v')}
    fontWeight="normal"
    fontSize="2xl"
    m="2"
  >
    V
  </Button>
  <Button
    _focus={{ bg: 'black', color: 'white' }}
    onClick={() => getFiltredAuthors('w')}
    fontWeight="normal"
    fontSize="2xl"
    m="2"
  >
    W
  </Button>
  <Button
    _focus={{ bg: 'black', color: 'white' }}
    onClick={() => getFiltredAuthors('x')}
    fontWeight="normal"
    fontSize="2xl"
    m="2"
  >
    X
  </Button>
  <Button
    _focus={{ bg: 'black', color: 'white' }}
    onClick={() => getFiltredAuthors('y')}
    fontWeight="normal"
    fontSize="2xl"
    m="2"
  >
    Y
  </Button>
  <Button
    _focus={{ bg: 'black', color: 'white' }}
    onClick={() => getFiltredAuthors('z')}
    fontWeight="normal"
    fontSize="2xl"
    m="2"
  >
    Z
  </Button>
</Flex>



      {!data && (
        <Box textAlign="center">
          <Spinner size="xl" />
        </Box>
      )}
      <Masonry
        breakpointCols={breakpointColumnsObj}
        className="my-masonry-grid"
        columnClassName="my-masonry-grid_column"
      >
        {data &&
          Object.values(data).map(author => (
            <Link key={author.id} to={`/author/${author.id}`}>
              <Box oveflow="hidden" pb="4" mt="4" mb="4" bg={bg[colorMode]}>
                <Skeleton w="100%" isLoaded={loaded}>
                  <Image
                    loading="lazy"
                    onLoad={imageLoaded}
                    w="100%"
                    //   h="150px"
                    // m="2"
                    src={`${process.env.REACT_APP_STORAGE}/${author.image}`}
                  ></Image>
                </Skeleton>
                <Text
                  _hover={{
                    bg: 'black',
                    color: 'white',
                    textDecoration: 'underline',
                  }}
                  mt="4"
                  mr="4"
                  fontSize="xl"
                  fontFamily="diodrum-med !important"
                >
                  {author.name}
                </Text>
              </Box>
            </Link>
          ))}
      </Masonry>
      {/* <Flex mt="8" flexWrap="wrap"></Flex> */}
    </Box>
  );
}

const mapDispatchToProps = dispatch => {
  return { getAuthors: letter => dispatch(getAuthors(letter)) };
};

export default connect(null, mapDispatchToProps)(Writers);
