import React from 'react';
import { Helmet } from 'react-helmet';

import {
  Box,
  Heading,
  Text,
  List,
  ListItem,
  Input,
  FormControl,
  FormLabel,
  Textarea,
  Select,
  Button,
  useToast,
} from '@chakra-ui/core';
import { connect } from 'react-redux';

import { postBook } from '../../redux/actions/booksActions';
import PickFile from './PickFile';

function Publishlist({ postBook }) {
  const toast = useToast();

  const [data, setData] = React.useState({
    email: null,
    phone: null,
    address: null,
    message: null,
    nickname: null,
    author_name: null,
    book_title: null,
    book_category: 'دراسة',
    book_language: 'الإنجليزية',
  });
  const [loading, setLoading] = React.useState(false);

  const handleChange = e => {
    e.persist();
    setData(prev => ({ ...prev, [e.target.name]: e.target.value }));
  };
  const handleSubmit = async () => {
    setLoading(true);
    const response = await postBook(data);
    if (response.status === 201) {
      setLoading(false);

      toast({
        title: 'نجاح',
        description: 'تمت العملية بنجاح',
        status: 'success',
        duration: 2000,
        isClosable: true,
      });
      setInterval(function () {
        window.location.replace('/home');
      }, 1000);
    }
  };

  return (
    <Box mt={{ base: 0, md: '6em' }} mb="100px">
      <Helmet>
        <title>انشر مع المتوسط</title>
      </Helmet>
      <Box
        textAlign="left"
        p="30px"
        pl={['5%', '5%', '20%', '20%']}
        pr={['5%', '5%', '20%', '20%']}
      >
        <Heading fontFamily="diodrum-bold !important" size="xl">
        Termini di pubblicazione
        </Heading>
        <Text fontSize="2xl" m="4">
        Al-Mutawassit Publications è onorata di ricevere i tuoi manoscritti su questa pagina
        </Text>
        <Text fontSize="2xl" m="4">
          La redazione esamina i testi inviati, secondo le sue competenze, e poi risponde in merito alla pubblicazione del manoscritto o si scusa per averlo pubblicato.
        </Text>
        <Text fontSize="2xl" m="4">
        Oltre agli standard della casa editrice, la redazione ha i propri standard letterari e artistici, che adotta per quanto riguarda la pubblicazione dei manoscritti. Nelle decisioni finali consulta anche i reparti di produzione e distribuzione.          
        </Text>
        <br />
        <Text fontWeight="bold" fontSize="2xl" m="4">
        Al-Mutawassit Publications vorrebbe che prendeste in considerazione, per quanto possibile, quanto segue:
        </Text>
        <List styleType="disc" fontSize="2xl" m="4">
          <ListItem>Invia il manoscritto completo.</ListItem>
          <ListItem>Invia una biografia sull'autore.</ListItem>
          <ListItem>
          Invia un riassunto dell'opera, se non si tratta di poesia o di un racconto.
          </ListItem>
          <ListItem>
          Il periodo necessario per prendere una prima decisione sul manoscritto è di massimo 10 giorni.
          </ListItem>
          <ListItem>
          Il tempo necessario per prendere una decisione definitiva sul manoscritto (eccetto poesie e racconti) può durare due mesi al massimo. Per quanto riguarda i capelli corti e i tagli di capelli, è un mese al massimo.
          </ListItem>
          <ListItem>
          Se desideri richiedere informazioni su qualche manoscritto, contatta la casa editrice solo via e-mail.
          </ListItem>
          <ListItem>
          La casa editrice si scusa per aver fornito qualsiasi motivo che l'abbia spinta a scusarsi per aver pubblicato qualsiasi manoscritto.
          </ListItem>
        </List>
        <br />
        <Text fontWeight="bold" fontSize="2xl" m="4">
        Pubblicazione all'interno di una serie di brevetti:
        </Text>
        <Text fontSize="2xl" m="4">
          Barat Basket è una serie speciale sulla poesia e sui racconti, e ha una giuria
          Un comitato indipendente composto da sei membri, l'approvazione di quattro dei quali è sufficiente per pubblicare il manoscritto.
        </Text>
        <Text fontSize="2xl" m="4">
        Il manoscritto viene inviato ai membri della commissione senza il nome dello scrittore e, se viene ottenuta l'approvazione del quarto membro, lo scrittore viene informato.
        </Text>
        <Text fontSize="2xl" m="4">
        Se il numero dei membri del comitato che approvano e disapprovano è uguale, la casa editrice chiede l'assistenza della redazione per risolvere la questione della pubblicazione del libro.
        </Text>
        <br />
        <Text fontWeight="bold" fontSize="2xl" m="4">
        Suggerimenti per la traduzione:
        </Text>
        <Text fontSize="2xl" m="4">
        Il traduttore invia il titolo del libro che si propone di tradurre (o ritradurre) nella lingua originale, oltre al motivo che lo ha spinto a tradurre questo libro, poi un riassunto del libro e infine un campione tradotto del libro non meno di 1.000 parole dal libro originale.        </Text>
        <Text fontSize="2xl" m="4">
        Ringraziamo tutti e vi diamo il benvenuto a far parte della famiglia Aldar con tutta cordialità.
        </Text>
        <br />
        <Box className="publish" p="4" rounded="10px">
          <Heading fontFamily="diodrum-bold !important" size="xl">
          Richiesta di pubblicazione di un libro: {' '}         
           </Heading>
          <FormControl w="90%" m="4">
            <FormLabel fontSize="xl" textAlign="left">
            Nome dell'autore
            </FormLabel>
            <Input
              onChange={handleChange}
              type="text"
              name="author_name"
              placeholder="Nome dell'autore"
            ></Input>
          </FormControl>
          <FormControl w="90%" m="4">
            <FormLabel fontSize="xl" textAlign="left">
            Cognome dell'autore
                        </FormLabel>
            <Input
              onChange={handleChange}
              name="nickname"
              type="text"
              placeholder=" Cognome dell'autore"
            ></Input>
          </FormControl>
          <FormControl w="90%" m="4">
            <FormLabel fontSize="xl" textAlign="left">
            Indirizzo postale dell'autore
            </FormLabel>
            <Input
              onChange={handleChange}
              name="address"
              type="text"
              placeholder=" Indirizzo postale dell'autore"
            ></Input>
          </FormControl>
          <FormControl w="90%" m="4">
            <FormLabel fontSize="xl" textAlign="left">
            E-mail dell'autore
            </FormLabel>
            <Input
              onChange={handleChange}
              name="email"
              type="email"
              placeholder="E-mail dell'autore"
            ></Input>
          </FormControl>
          <FormControl w="90%" m="4">
            <FormLabel fontSize="xl" textAlign="left">
            Numero di telefono fisso o mobile
            </FormLabel>
            <Input
              onChange={handleChange}
              name="phone"
              type="text"
              placeholder="Numero di telefono fisso o mobile"
            ></Input>
          </FormControl>
          <FormControl w="90%" m="4">
            <FormLabel fontSize="xl" textAlign="left">
            Il titolo del libro
            </FormLabel>
            <Input
              onChange={handleChange}
              name="book_title"
              type="text"
              placeholder=" Il titolo del libro"
            ></Input>
          </FormControl>
          <FormControl w="90%" m="4">
            <FormLabel fontSize="xl" textAlign="left">
            Genere del libro
            </FormLabel>
            <Select onChange={handleChange} name="book_category" placeholder="">
              <option value="studio"> studio</option>
              <option value="un romanzo"> un romanzo</option>
              <option value=" storia breve"> storia breve </option>
              <option value="Poesia"> Poesia</option>
              <option value="memorie"> memorie</option>
              <option value="Viaggi"> Viaggi</option>
              <option value=" Altro>">Altro</option>
            </Select>
          </FormControl>
          <FormControl w="90%" m="4">
            <FormLabel fontSize="xl" textAlign="left">
            Lingua originale
                        </FormLabel>
            <Select onChange={handleChange} name="book_language" placeholder="">
            <option value="Inglese">Inglese</option>
<option value="Cinese">Cinese</option>
<option value="Indiano">Indiano</option>
<option value="Arabo">Arabo</option>
<option value="Spagnolo">Spagnolo</option>
<option value="Russo">Russo</option>
<option value="Portoghese">Portoghese</option>
<option value="Bengalese">Bengalese</option>
<option value="Francese">Francese</option>
<option value="Tedesco">Tedesco</option>

            </Select>
          </FormControl>
          <FormControl w="90%" m="4">
            <FormLabel fontSize="xl" textAlign="left">
            Lasciate un messaggio{' '}
             </FormLabel>
            <Textarea
              onChange={handleChange}
              name="message"
              type="text"
              placeholder="Lasciate un messaggio"
            ></Textarea>
          </FormControl>
          <Box w="90%" m="4">
            <PickFile setData={setData}></PickFile>
          </Box>
          {/* <FormControl w="90%" m="4">
            <FormLabel fontSize="xl" textAlign="left">تحميل المخطوط </FormLabel>
            <Input
              onChange={handleChange}
              name="file"
              type="file"
              placeholder="تحميل المخطوط"
            ></Input>
          </FormControl> */}
          <Text color="gray.500" fontSize="2xl" m="4">
          Mi impegno a non sottoporre questo manoscritto a nessun altro editore e lo farò cliccando un pulsante
 (Presentazione) Aspetterò l'approvazione iniziale o le scuse, un massimo di 10 giorni
 A partire dal momento in cui si fa clic sul pulsante (Invia).
          </Text>
          <FormControl w="90%" m="4">
            <Button
              isLoading={loading}
              isDisabled={
                !data.file ||
                !data.address ||
                !data.phone ||
                !data.message ||
                !data.email ||
                !data.nickname ||
                !data.author_name ||
                !data.book_title ||
                !data.book_language ||
                !data.book_category
              }
              w="100%"
              _hover={{ bg: '#212121' }}
              bg="black"
              color="white"
              onClick={handleSubmit}
              size="lg"
            >
              presentazione
            </Button>
          </FormControl>
        </Box>
      </Box>
    </Box>
  );
}

const mapDispatchToProps = dispatch => {
  return { postBook: letter => dispatch(postBook(letter)) };
};

export default connect(null, mapDispatchToProps)(Publishlist);
