import React from 'react';
import { Helmet } from 'react-helmet';

import { Box, Heading, Text, List, ListItem, Image } from '@chakra-ui/core';
import translation from '../../images/conf-min.jpg';

export default function publishlist() {
  return (
    <Box mt="100px" mb="100px">
      <Helmet>
        <title> Forum della Traduzione e della Letteratura Mondiale verso l'istituzionalizzazione dell'azione culturale </title>
      </Helmet>
      <Heading
        pl={['5%', '5%', '20%', '20%']}
        pr={['5%', '5%', '20%', '20%']}
        size="xl"
        fontFamily="diodrum-bold !important"
      >
               Forum della Traduzione e della Letteratura Mondiale verso l'istituzionalizzazione dell'azione culturale
        {/* <Divider
          mt="4"
          opacity="1"
          border="3px solid black"
          pl="20%"
          pr="20%"
          //   m="4"
          borderColor="black"
          w="20%"
        ></Divider> */}
      </Heading>
      <Box d="flex" justifyContent="center" m="4">
        <Image
          loading="lazy"
          w={['90%', '90%', '60%', '60%']}
          src={translation}
        ></Image>
      </Box>
      <Box
        textAlign="left"
        p="30px"
        pl={['5%', '5%', '20%', '20%']}
        pr={['5%', '5%', '20%', '20%']}
      >
        <Text fontSize="2xl" m="4">
        Al-Mutawassit Publications ha lanciato l’idea di organizzare un forum di traduzione nel 2016, che si è tenuto un anno
 Il primo si è svolto nella città italiana di Milano, e il suo obiettivo era quello di evidenziare...
 La traduzione come attività culturale, istituzione e attore in qualsiasi processo di scambio culturale
 E tra culturale.
        </Text>
        <Text fontSize="2xl" m="4">
        Di conseguenza, il Forum di Traduzione vuole essere una piattaforma aperta per scrittori e traduttori arabi e stranieri con progetti specifici e seri riguardanti le questioni della traduzione dall'arabo verso e dalle lingue straniere. Come può questo atto creativo andare oltre i contesti artistici e letterari per raggiungere ciò che è umanitario nel costruire ponti tra popoli e civiltà e nel superare le barriere politiche e geografiche tra i paesi del mondo?
        </Text>
        <Text fontSize="2xl" m="4">
        Il Forum della Traduzione è anche un incontro aperto tra pensatori, scrittori e traduttori arabi
 E traduttori stranieri dalla lingua araba ed editori stranieri, mirati a un pubblico
 Interessa innovatori, studenti e ricercatori e si dedica a parlare di traduzione
 E le sue prospettive, l'importanza di tradurre e pubblicare la letteratura araba e i meccanismi per rivitalizzarla nelle lingue
 L'altro.
        </Text>
        <Text fontSize="2xl" m="4">
        Ospiti del forum:
         </Text>
        <List m="4" fontSize="2xl" styleType="decimal">
          <ListItem>
          Scrittori e pensatori stranieri e loro traduttori ed editori in lingua araba
          </ListItem>
          <ListItem>
          Traduttori dall'arabo verso altre lingue, anche arabisti
          </ListItem>
          <ListItem>Traduttori da altre lingue in arabo</ListItem>
          <ListItem>
          Editori stranieri interessati a tradurre la letteratura araba e pubblicarla nelle loro lingue e titolari di vari progetti culturali.          </ListItem>
        </List>
        <Text fontSize="2xl" m="4">
        Poiché Al-Mutawassit Publications è solo un'istituzione privata con capacità limitate, ha sempre lavorato e creduto in un principio che ha cercato di stabilire, ovvero il principio dell'integrazione culturale tra istituzioni culturali private e istituzioni culturali e comunitarie ufficiali e semiufficiali, in particolare ministeri della cultura, istituti culturali e università, in particolare nei paesi arabi del bacino del Al-Mutawassit, e lavorando per espandere ulteriormente il campo degli scambi culturali e umanitari, ospitando nomi illustri i cui progetti hanno una dimensione culturale e una storia comune, come il pensatore e professore universitario Hamid Dabbashi, lo scrittore e giornalista italiano Giuseppe Catocella, la ricercatrice e docente universitaria spagnola Luz Gomez Garcia, Jonathan Wright, il critico e traduttore britannico di letteratura araba in inglese, il traduttore e ricercatore tunisino Ezzedine Inaya, e la scrittrice e traduttrice irachena Douna Ghali.
        </Text>
        <Text fontSize="2xl" m="4">
Al-Mutawassit Publications non vede l'ora di organizzare un forum di traduzione in diverse città arabe
 Con l’obiettivo di istituzionalizzare l’atto culturale della traduzione e di aprire un discorso allargato che trasmetta da
 E alla lingua araba, alle esperienze dei popoli mediterranei e alle loro problematiche contemporanee,
 All'interno di una visione che celebra le diverse e diversificate dimensioni della cultura mediterranea.
 Pertanto, le pubblicazioni mediterranee continuano a cercare partner che contribuiscano a trasmetterlo
 progetto nei loro paesi. Soprattutto perché la nostra esperienza ha ottenuto un solido successo con risultati evidenti.{' '}
        </Text>
        <Heading m="4" size="lg">
        Sulla nostra precedente esperienza in Palestina:
        </Heading>
        <Text fontSize="2xl" m="4">Per due anni consecutivi, 2017 e 2018, la Palestina ha ospitato le attività: Translation Days
 E la letteratura mondiale, sotto lo slogan: “Palestina: civiltà e comunicazione della conoscenza”, in collaborazione
 Tra Edizioni Mediterranee e Ministero della Cultura Palestinese. I giorni e i loro cicli
 Il primo e il secondo sono nati con l'obiettivo di istituzionalizzare l'azione culturale e continuare la lotta letteraria
 Gli sforzi artistici e umanitari del popolo palestinese per la libertà. Dove si stabilì nella città di Ram
 Inoltre, Dio dispone di un certo numero di scrittori internazionali accompagnati dai loro traduttori arabi in arabo
 A traduttori che hanno esperienze proprie nella traduzione in generale e nella traduzione letteraria
 Dall’arabo ad altre lingue in particolare, per non parlare del loro interesse per le crisi umane
 Questioni contemporanee di patria, libertà, religione e dialogo tra le civiltà.
        </Text>
      </Box>
    </Box>
  );
}
