import React from 'react';
import { Box, Text, Heading, Image } from '@chakra-ui/core';
// import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import aboutpic from '../images/about-min.jpg';
export default function blog() {
  return (
    <Box mt={{ base: '2em', md: '6em' }} mb="100px">
      <Helmet>
        <title> Nella Al-Mutawassit</title>
      </Helmet>
      <Heading
        mr="4"
        pl={['5%', '5%', '20%', '20%']}
        pr={['5%', '5%', '20%', '20%']}
        size="xl"
        fontFamily="diodrum-bold !important"
      >
        Nella Al-Mutawassit
        {/* <Divider
          mt="4"
          opacity="1"
          border="3px solid black"
          pl="20%"
          pr="20%"
          //   m="4"
          borderColor="black"
          w="20%"
        ></Divider> */}
      </Heading>
      <Box d="flex" justifyContent="center" m="4">
        <Image
          loading="lazy"
          shadow="lg"
          rounded="10px"
          alignSelf="start"
          w={['90%', '90%', '60%', '60%']}
          h={'auto'}
          src="https://almutawassit.it/almutawassit.png"
        ></Image>
      </Box>
      <Box
        textAlign="left"
        pl={['5%', '5%', '20%', '20%']}
        pr={['5%', '5%', '20%', '20%']}
      >
        <Text fontSize="2xl" m="4">Al-Mutawassit è una casa editrice indipendente con una posizione radicale e che stabilisce un dinamismo
          Le sue scelte e i suoi meccanismi d’azione si basano su questa posizione. Sono attive le pubblicazioni mediterranee
          Nei paesi arabi si pubblicano libri nel campo della letteratura, del pensiero e della cultura in generale.
        </Text>
        <br />
        <Heading size="lg" m="4">
          Al-Mutawassit dall'inizio ad oggi
        </Heading>
        <Text fontSize="2xl" m="4">
          Al-Mutawassit Publications è stata fondata alla fine del 2015 a Milano, in Italia, come progetto di
          Progetti dell'Associazione Mediterraneo, che opera nel campo dello scambio culturale tra gruppi diversi
          Le civiltà e i popoli del mondo, e il fatto che sia stata fondata a Milano da arabi interessati
          Con la cultura italiana, e gli italiani interessati alla cultura araba, lo è
          Nel suo campo di lavoro si concentra sullo scambio culturale tra il mondo arabo e l'Italia
          Prima, poi tra mondo arabo ed Europa, e tra Italia e mondo.
        </Text>

        <Text fontSize="2xl" m="4">
          Subito dopo la sua fondazione, le pubblicazioni Al-Mutawassit riuscirono ad affermarsi in breve tempo
          Come una delle case editrici più attive nel mondo arabo. E superare il numero
          Ad oggi sono stati pubblicati 300 titoli, variando tra pensiero e letteratura. E mi sono sottomesso
          Casa della Cultura Araba: Grandi nomi tradotti in arabo per la prima volta.
          Citiamo, ma non ci limitiamo a: Emilio Salgari, Paolo Sorrentino, Giuseppe
          Catocella, Francesca Bellino, Guido Gozzano, Karl Chapek, Naia Maria
          Ait, Perry Anderson, Marta Hellers, Ronaldo Munk, Clement Rossi, Pipes
          Skliar, Wang Xiaobo, Tom Christens, Abbas Maroufi, Bruce Lowry.
        </Text>

        <Text fontSize="2xl" m="4">
          La casa attirò rapidamente importanti nomi internazionali della letteratura e si pensò che ne fossero membri
          Tra coloro i cui libri sono pubblicati nel mondo arabo in traduzioni originali da altre lingue, come: Paolo
          Auster, Joyce Carol Oates, Juan José Mias, Javier Marías, Yu Hua,
          Alessandro Barico, Souad Al-Amiri.
        </Text>

        <Text fontSize="2xl" m="4">
          La casa era nota anche per il suo interesse per la nuova letteratura, poiché pubblicava romanzi, poesie e racconti di molti giovani scrittori, alcuni dei quali pubblicavano i loro libri per la prima volta, e alcuni di loro pubblicavano i loro primi romanzi, come come: Al-Arabi Ramadani, Saleha Obaid, Nisreen Akram Khoury, Laila Abdullah e altri. È interessante notare che il Al-Mutawassit, fin dalle sue origini, ha cercato di trarre vantaggio dalle esperienze di successo dell’editoria, e di quanto realizzato nella storia dell’editoria araba in particolare, per aggiungere ad essa le proprie idee, come l’identità artistica del casa, in termini di titoli, progetti che avvia e collane che lavora per pubblicare; Come la serie Arabic Diaries e la serie "Barat", che è una serie di poesie, testi e racconti arabi, pubblicati da nomi sconosciuti nel mondo arabo e da vari paesi, senza eccezioni tranne la qualità del testo, e non passò molto tempo dal lancio della serie, finché non attirò grandi nomi nel mondo della poesia e dei racconti, come: Qasim Haddad, Fawzi Karim, Hala Muhammad, Abdo Wazen, Charbel Dagher. Ha raggiunto il suo nome come un progetto che oggi ha raggiunto più di 80 titoli.
        </Text>

        <Text fontSize="2xl" m="4">
          Oltre al contenuto, il Al-Mutawassit si è concentrato sull'aspetto artistico della produzione, del design e della stampa del libro, e ha riscosso un buon successo. Alcuni specialisti e critici culturali hanno addirittura affermato che il Al-Mutawassit ha istituito una scuola speciale nella produzione di libri.
        </Text>

        <Text fontSize="2xl" m="4">
          Nel 2016, Al-Mutawassit Publications ha lanciato anche una serie letteraria intitolata “La letteratura è più forte”, pubblicando un'edizione palestinese di alcuni dei suoi libri contemporaneamente alla pubblicazione della sua edizione nel mondo arabo. Ciò rappresenta un'opportunità per i libri della casa editrice essere parte del lavoro di resistenza alle autorità di occupazione, rompendo le restrizioni imposte loro sui lettori palestinesi e sui libri che desiderano essere presenti nei territori palestinesi occupati.
        </Text>

        <Text fontSize="2xl" m="4">Nel 2016, Al-Mutawassit Publications ha anche lanciato una serie letteraria intitolata “La letteratura è più forte”.
          Pubblicando un'edizione palestinese di alcuni dei suoi libri in concomitanza con la pubblicazione dell'edizione internazionale
          Al-Arabi, che rappresenta un'opportunità per la casa editrice di partecipare al lavoro di resistenza
          Alle autorità di occupazione, infrangendo le restrizioni imposte al lettore palestinese,
          E sui libri che vogliono essere presenti nei territori palestinesi occupati.
        </Text>

        <Text fontSize="2xl" m="4">
          Il 21 marzo 2019, Al-Mutawassit Publications ha lanciato il primo numero della rivista “Barat”, nelle edizioni araba e palestinese. la rivista Barat, di cui sono stati pubblicati finora quattro numeri; È una rivista araba trimestrale che si occupa di tutto ciò che riguarda la poesia, compresi testi, articoli critici, studi teorici e applicati, dialoghi, relazioni, racconti e un approccio tra poesia e altre arti.
        </Text>
        <br />
        <Heading size="lg" m="4">
          In altre lingue:
        </Heading>
        <Text fontSize="2xl" m="4">
          Alla fine del 2018 è stata lanciata "Muta", la versione italiana di "Al-Mutawassit Publications", con l'obiettivo di tradurre la letteratura araba in italiano e portare la voce araba in modo chiaro e generale al mondo occidentale. Ha pubblicato diversi libri, tra cui la traduzione del libro "Probabilmente a causa di una nuvola" del poeta libanese Wadie Saadeh, il libro "Studi sul romanzo arabo" di un gruppo di autori arabi e un'antologia di poesie arabe con il progetto COSV. La casa editrice "Muta" lavora anche come consulente per alcuni editori italiani interessati a tradurre la letteratura araba.
        </Text>
        <br />
        <Heading size="lg" m="4">
          Nella stampa internazionale:
        </Heading>
        <Box color="black" fontSize="2xl" m="4">
          <figure class="quote">
            <blockquote>
              "Nonostante la sua breve vita, Al-Mutawassit Publications offre un modello moderno, diverso e illuminato, per la scelta dei libri arabi e tradotti, e per l'identità creativa della casa editrice".
            </blockquote>
            <figcaption>
              &mdash; Newsletter della London International Book Fair <cite>The Publishing Perspectives</cite>
            </figcaption>
          </figure>
        </Box>
        <Text fontSize="2xl" m="4">
          La fama di "Al-Mutawassit Publications" è arrivata a livello globale, quando nel 2016 il giornale francese specializzato in libri e pubblicazioni (Livres Hebdo) le ha dedicato la prima pagina con un articolo intitolato: <b>"Al-Mutawassit – Una forma di pubblicazione rivoluzionaria nel mondo".</b>
        </Text>
        <Text fontSize="2xl" m="4">
          Nel 2017, il sito specializzato in pubblicazioni (Linkesta), il più famoso in Italia, ha pubblicato un lungo articolo intitolato: <b>"Pubblicazione araba da Milano".</b>
        </Text>
        <Text fontSize="2xl" m="4">
          Nello stesso anno, Al-Mutawassit Publications ha ricevuto una lettera ufficiale di ringraziamento dal Ministero della Cultura ceco per la traduzione di un'opera del più grande scrittore ceco, il romanziere e drammaturgo Karel Čapek.
        </Text>
        <Text fontSize="2xl" m="4">
          Nel 2019, la newsletter della London International Book Fair, The Publishing Perspectives, ha presentato Al-Mutawassit Publications con un articolo di tre pagine intitolato <a class="a-special" href="https://publishingperspectives.com/2019/03/al-mutawassit-arabic-publisher-italy-fiction-poetry-design/"><b>"Al-Mutawassit: Una forza creativa nel mondo dell'editoria araba".</b></a>
        </Text>
        <Text fontSize="2xl" m="4">
          Infine, "Al-Mutawassit Publications", che ha adottato come logo Don Chisciotte in bicicletta invece che a cavallo, intende produrre una conoscenza che unisca originalità e modernità. Perciò il nostro Don Chisciotte vaga, gridando tra la gente: "Insieme combatteremo i mulini a vento". Ma sa perfettamente che sono assolutamente reali.
        </Text>
      </Box>
    </Box>

  );
}
