import React from 'react';
import { Box, Heading, Text, Divider } from '@chakra-ui/core';
import { Helmet } from 'react-helmet';

export default function standards() {
  return (
    <Box mt="100px" mb="100px">
      <Helmet>
        <title>Contatta El Al-Mutawassit</title>
      </Helmet>
      <Heading
        mr="4"
        pl={['5%', '5%', '20%', '20%']}
        pr={['5%', '5%', '20%', '20%']}
        size="xl"
      >
chiamaci
        <Divider
          opacity="1"
          border="3px solid black"
          pl="20%"
          pr="20%"
          //   m="4"
          borderColor="black"
          w="20%"
        ></Divider>
      </Heading>
      <Box
        textAlign="left"
        pl={['5%', '5%', '20%', '20%']}
        pr={['5%', '5%', '20%', '20%']}
      >
        <Heading size="lg" m="4">
          {' '}
          Ufficio principale
        </Heading>
        <Text fontSize="2xl" m="4">
          Almutawassit Books
        </Text>
        <Text fontSize="2xl" m="4">
          Alzaia Navilio Pavese, 120
        </Text>
        <Text fontSize="2xl" m="4">
          20142 Milano
        </Text>
        <Text fontSize="2xl" m="4">
          Italy
        </Text>
        <Divider></Divider>
      
        <br />
        <Heading size="lg" m="4">
          {' '}
          Progetti editoriali e di scambio culturale:
         </Heading>
        <Text fontSize="2xl" m="4">
        Direttore Generale: Khaled Suleiman Al Nasseri {' '}
        </Text>
        <Text fontSize="2xl" m="4">
          Khaled@almutawassit.org
        </Text>
        <Divider></Divider>
        <br />
        <Heading size="lg" m="4">
          {' '}
          Comunicazione pubblica
        </Heading>
        <Text fontSize="2xl" m="4">
          info@almutawassit.org{' '}
        </Text>
      </Box>
    </Box>
  );
}
