import React from 'react';
import { Helmet } from 'react-helmet';

import { Box, Heading, Text, Image } from '@chakra-ui/core';
import divine from '../../images/comedy-min.jpg';

export default function publishlist() {
  return (
    <Box mt="100px" mb="100px">
      <Helmet>
        <title> La Divina Commedia</title>
      </Helmet>
      <Heading
        fontFamily="diodrum-bold !important"
        pl={['5%', '5%', '20%', '20%']}
        pr={['5%', '5%', '20%', '20%']}
        size="xl"
      >
        La Divina Commedia
      </Heading>
      <Text
        fontFamily="diodrum-med !important"
        pl={['5%', '5%', '20%', '20%']}
        pr={['5%', '5%', '20%', '20%']}
        fontSize="2xl"
      >
        Riproduzione della "Divina Commedia" in arabo
        {/* <Divider
          mt="4"
          opacity="1"
          border="3px solid black"
          pl="20%"
          pr="20%"
          //   m="4"
          borderColor="black"
          w="20%"
        ></Divider> */}
      </Text>

      <Box d="flex" justifyContent="center" m="4">
        <Image
          loading="lazy"
          w={['90%', '90%', '60%', '60%']}
          src={divine}
        ></Image>
      </Box>
      <Box
        textAlign="left"
        p="30px"
        pl={['5%', '5%', '20%', '20%']}
        pr={['5%', '5%', '20%', '20%']}
      >
        <Text fontSize="2xl" m="4">
          Le Edizioni Al-Mutawassit hanno iniziato a lavorare sulla riproduzione della traduzione di Hassan Osman del libro "La Divina Commedia" di Dante Alighieri, incaricando una commissione scientifica di accademici e traduttori di spicco specializzati nella traduzione e trasmissione del patrimonio tra le lingue italiana e araba di avviare il lavoro sul progetto.
        </Text>
        <Text fontSize="2xl" m="4">
          Questo passo è stato compiuto per far rivivere l'opera di Dante Alighieri in arabo, che ha segnato l'alba del Rinascimento europeo e ha mantenuto il suo splendore e la sua influenza continua su generazioni successive di scrittori, pensatori e artisti che hanno arricchito le loro fonti artistiche e linguistiche leggendo quest'opera unica in innumerevoli lingue in tutto il mondo e con diverse traduzioni in una stessa lingua.
        </Text>
        <Text fontSize="2xl" m="4">
          L'interesse arabo per la Divina Commedia, iniziato negli anni Ottanta del XIX secolo, ha successivamente prodotto più di una traduzione dell'opera, ognuna delle quali ha aggiunto illuminazioni essenziali e importanti all'opera letteraria più importante nella storia della letteratura italiana, se non mondiale. Ma tra tutte queste traduzioni, quella di Hassan Osman, storico e professore di storia islamica ed europea, rimane la più attraente e luminosa per molti motivi. Hassan Osman ha dedicato dieci anni, dal 1941 al 1951, allo studio di Dante e in particolare della Divina Commedia, iniziando poi a tradurre l'opera, un lavoro che gli ha richiesto altri otto anni. Durante questo periodo ha viaggiato a sue spese, talvolta coperto dall'UNESCO, visitando le fonti di studio di Dante, seguendo le orme del poeta e vivendo nei luoghi in cui ha vissuto, esplorando le arti figurative e la musica per studiare il gusto artistico e musicale di Dante. Se gli studiosi hanno discusso sulla durata necessaria a Dante per scrivere la Divina Commedia, alcuni affermando quattordici anni e altri ventidue, siamo certi che il suo traduttore in arabo ha impiegato diciotto anni, cosa che, a nostra conoscenza, non è accaduta in altre lingue.
        </Text>
        <Text fontSize="2xl" m="4">
          Hassan Osman, prima di essere traduttore, era un ricercatore pionieristico nel metodo di ricerca storica, il cui libro intitolato "Metodo di ricerca storica" è ancora un riferimento essenziale nel suo campo fino ai giorni nostri, ed è riuscito ad applicare sinceramente questo metodo nel suo lavoro sulla traduzione del libro che viene classificato secondo solo alla Bibbia in Occidente, ossia la Divina Commedia. Per questo motivo la sua traduzione della Divina Commedia ha superato i confini della traduzione stessa, risultando in uno studio storico completo di un periodo cruciale nella storia del pensiero umano, ossia l'emergere del Rinascimento. Pertanto, questo progetto non è solo una celebrazione di Dante e della Divina Commedia, ma anche di questo traduttore eccezionale, non solo in ambito arabo ma a livello mondiale. Speriamo di riuscire a realizzare le sue speranze, espresse alla fine della sua traduzione della Divina Commedia, quando disse: «Spero che verrà qualcuno che studierà, viaggerà, pazienterà, comprenderà, rifletterà, si ispirerà e prenderà la penna per esprimere Dante in una forma più completa e più bella!»
        </Text>
        <Text fontSize="2xl" m="4">
          I partecipanti hanno iniziato il lavoro sulla traduzione, formando una commissione scientifica composta da un gruppo dei più brillanti traduttori specializzati nella traduzione e trasmissione del patrimonio tra le lingue italiana e araba, supportata da un gruppo di accademici specializzati in storia medievale, letteratura e teologia, nella lingua di Dante e nella nuova retorica da lui fondata e nell'impatto che ha avuto fino ad oggi sulla letteratura e sulle arti occidentali, e infine nella letteratura comparata, seguendo le tracce di altre culture nella Divina Commedia e il suo impatto translinguistico su altre culture. Questa commissione ha sviluppato una visione comune su ciò che dovrebbe essere aggiunto e aggiornato nel testo, basandosi sugli studi moderni sulla Divina Commedia pubblicati negli ultimi anni. In breve, studieranno, viaggeranno, pazienteranno, comprenderanno, rifletteranno, si ispireranno e prenderanno la penna per esprimere la traduzione di Hassan Osman in una forma più completa e più bella, riflettendo così una visione più completa e più bella di Dante.
        </Text>
        <Text fontSize="2xl" m="4">
          Le Edizioni Al-Mutawassit hanno lanciato questo progetto a pochi mesi dal 700º anniversario della morte di Dante Alighieri, sperando che il progetto venga alla luce in occasione del 50º anniversario della morte di Hassan Osman, che ricorrerà nel 2023.
        </Text>
        <Text fontSize="2xl" m="4">
          Sin dalla sua fondazione, Al-Mutawassit si è impegnata nella trasmissione della letteratura e del pensiero italiano in lingua araba, offrendo molte opere di grandi autori tradotte per la prima volta in arabo e dedicandosi anche alla revisione, restauro e aggiornamento delle vecchie traduzioni di valore, iniziando con il romanzo "Il Gattopardo" di Giuseppe Tomasi di Lampedusa e il romanzo "Fontamara" di Ignazio Silone. Questo ha reso la casa editrice specializzata in letteratura italiana, tanto quanto si dedica al mondo arabo.
        </Text>
        <Heading size="lg" m="4">
          Accademici e traduttori partecipanti in ordine alfabetico:
        </Heading>
        <Text fontSize="2xl" m="4">
          <b>Amargi: </b>il nome letterario del poeta e traduttore siriano Rami Younes. Nato nella città di Latakia nel 1980, ha pubblicato quattro raccolte di poesie: «N», 2008. «Perugia: il testo-il corpo», 2009. «Sale erotiche», 2011. «Rosa dell'animale», 2014. «Filologia dei fiori», 2020. Ha conseguito due lauree magistrali presso le università di Catania e Perugia in Italia, specializzandosi nella traduzione della letteratura italiana, traducendo in arabo molte opere di grandi scrittori e poeti, tra cui "Oceano mare" di Alessandro Baricco, 2017. "Uno, nessuno e centomila" di Luigi Pirandello, 2017. "Il fiore della resurrezione (le meraviglie del terzo millennio)" di Emilio Salgari, 2018. Tutte pubblicate da Al-Mutawassit.
        </Text>
        <Text fontSize="2xl" m="4">
          Amargi è stato insignito di diversi premi letterari, tra cui il primo premio al concorso letterario italiano "Antonio Russo" nel 2005 per una poesia inedita intitolata "Lezioni di letteratura", e il premio internazionale di poesia "Napoli Cultural Classic" per una poesia intitolata "Lezioni di guerra" nel 2006.
        </Text>
        <Text fontSize="2xl" m="4">
          <b>Dr. Ahmed Farouq Moussa:</b> Professore di letteratura comparata e traduzione letteraria e accademico specializzato in studi medievali. Ha conseguito la laurea magistrale e il dottorato in letteratura medievale presso l'Università di Torino, in Italia. È professore di letteratura e traduzione presso il Dipartimento di Lingue e Traduzione all'Università di Hama in Siria.
        </Text>
        <Text fontSize="2xl" m="4">
          <b>Dr. Khaled Matini: </b>Professore di letteratura e traduzione presso il Dipartimento di Lingue e Traduzione all'Università di Hama in Siria.
        </Text>
      </Box>
    </Box>
  );
}
